<template>
  <div id="product">
    <el-dialog
      :title="productFormTitle"
      width="500px"
      :visible.sync="productDialogVisible"
      :close-on-click-modal="false"
      @close="productDialogClose"
    >
      <el-form
        ref="productFormRef"
        :model="productForm"
        :rules="productFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-form-item label="物料名称" prop="name">
          <el-input v-model="productForm.name" placeholder="请输入物料名称" clearable />
        </el-form-item>
        <el-form-item label="规格/型号" prop="spec">
          <el-input v-model="productForm.spec" placeholder="请输入规格/型号" clearable />
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select v-model="productForm.type" placeholder="请选择类型" clearable>
            <el-option
              v-for="item in typeList"
              :key="item.id"
              :label="item.label"
              :value="item.code"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="单位" prop="unit">
          <el-input v-model="productForm.unit" placeholder="请输入单位" clearable />
        </el-form-item>
        <el-form-item label="单价" prop="unitPrice">
          <el-input v-model="productForm.unitPrice" placeholder="请输入单价" clearable />
        </el-form-item>
        <el-form-item label="技术指标" prop="technicalIndex">
          <el-input v-model="productForm.technicalIndex" placeholder="请输入技术指标" clearable />
        </el-form-item>
        <el-form-item label="备注" prop="remarks">
          <el-input v-model="productForm.remarks" placeholder="请输入备注" clearable />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="productDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="productFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="物料名称">
        <el-input v-model="searchForm.name" placeholder="请输入物料名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="productPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="name" label="物料名称" />
      <el-table-column prop="spec" label="规格/型号" />
      <el-table-column label="类型">
        <template slot-scope="scope">
          <span>{{ typeMap[scope.row.type] }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="unit" label="单位" />
      <el-table-column prop="unitPrice" label="单价" />
      <el-table-column prop="technicalIndex" label="技术指标" />
      <el-table-column prop="remarks" label="备注" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <!-- <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="productPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addProduct, deleteProduct, updateProduct, selectProductInfo, selectProductList } from '@/api/purchase/product'
import { selectDictList } from '@/api/system/dict'

export default {
  data () {
    return {
      productDialogVisible: false,
      productFormTitle: '',
      productForm: {
        id: '',
        name: '',
        spec: '',
        type: '',
        unit: '',
        unitPrice: '',
        technicalIndex: '',
        remarks: ''
      },
      productFormRules: {
        name: [
          {
            required: true,
            message: '物料名称不能为空',
            trigger: ['blur', 'change']
          }
        ],
        spec: [
          {
            required: true,
            message: '规格/型号不能为空',
            trigger: ['blur', 'change']
          }
        ],
        type: [
          {
            required: true,
            message: '类型不能为空',
            trigger: ['blur', 'change']
          }
        ],
        unit: [
          {
            required: true,
            message: '单位不能为空',
            trigger: ['blur', 'change']
          }
        ]
      },
      productPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        name: ''
      },
      typeList: [],
      typeMap: []
    }
  },
  created () {
    selectDictList(1).then(res => {
      this.typeList = res
      for (let i = 0; i < res.length; i++) {
        this.typeMap[res[i].code] = res[i].label
      }
    })
    selectProductList(this.searchForm).then(res => {
      this.productPage = res
    })
  },
  methods: {
    productDialogClose () {
      this.$refs.productFormRef.resetFields()
    },
    productFormSubmit () {
      this.$refs.productFormRef.validate(async valid => {
        if (valid) {
          if (this.productFormTitle === '新增物料') {
            await addProduct(this.productForm)
          } else if (this.productFormTitle === '修改物料') {
            await updateProduct(this.productForm)
          }
          this.productPage = await selectProductList(this.searchForm)
          this.productDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.productFormTitle = '新增物料'
      this.productDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteProduct(row.id)
        if (this.productPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.productPage = await selectProductList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.productFormTitle = '修改物料'
      this.productDialogVisible = true
      selectProductInfo(row.id).then(res => {
        this.productForm.id = res.id
        this.productForm.name = res.name
        this.productForm.spec = res.spec
        this.productForm.type = res.type
        this.productForm.unit = res.unit
        this.productForm.unitPrice = res.unitPrice
        this.productForm.technicalIndex = res.technicalIndex
        this.productForm.remarks = res.remarks
      })
    },
    handleInfo (index, row) {
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectProductList(this.searchForm).then(res => {
        this.productPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectProductList(this.searchForm).then(res => {
        this.productPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectProductList(this.searchForm).then(res => {
        this.productPage = res
      })
    }
  }
}
</script>

<style>
</style>
